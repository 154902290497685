import 'antd/lib/style/index.less'; // antd core styles
import './components/kit/vendors/antd/themes/default.less'; // default theme antd components
import './components/kit/vendors/antd/themes/dark.less'; // dark theme antd components
import './global.scss'; // app & third-party component styles

import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
// import { logger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducers from './redux/reducers';
import sagas from './redux/sagas';
import Localization from './localization';
import Router from './router';
import * as serviceWorker from './serviceWorker';
import { setTitlePage, getAppTitle } from './helpers';
import './version';

// mocking api
import 'services/axios/fakeApi';

// Estilos generales
import './genericComponents/CustomEditableFormTable/style.css';
import './genericComponents/CustomForm/style.less';
import './genericComponents/CustomHeaderSub/style.css';
import './genericComponents/CustomHelperText/style.css';
import './genericComponents/CustomNormalTable/style.css';
import './views/user/PaymentMethods/FormPaymentMethod/styles.css';
import './views/billing/Invoice/style.css';
import './views/catalog/index.css';
import 'antd/dist/antd.css';
import 'antd-country-phone-input/dist/index.css';

// middlewared
const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [sagaMiddleware, routeMiddleware];
// if (process.env.NODE_ENV === 'development') {
//   middlewares.push(logger)
// }
const store = createStore(
  reducers(history),
  composeWithDevTools(compose(applyMiddleware(...middlewares))),
);
sagaMiddleware.run(sagas);

setTitlePage(getAppTitle)();

// Local
global.domain = 'http://localhost:5001';
if (!window.location.href.includes('localhost')) {
  // global.domain = 'https://api.stampi.app';
  global.domain = 'https://billing-api.levita.dev';
}
// Web Dev
// global.domain = 'https://api.stampi.app';
// global.domain = 'https://billing-api.levita.dev';

ReactDOM.render(
  <Provider store={store}>
    <Localization>
      <Router history={history} />
    </Localization>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
export { store, history };
